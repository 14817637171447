import toLower from 'lodash/toLower';
import * as types from '../types';

export const DEFAULT_STATE = {
  user: {},
  lang: '',
};

export default (state = DEFAULT_STATE, action = {}) => {
  switch (action.type) {
    case types.APPLICATION_SET_USER: return {
      ...state,
      user: action.payload,
    };
    case types.APP_SET_LANG: return {
      ...state,
      lang: toLower(action.payload),
    };
    default: return state;
  }
};
