import * as types from '../types';

export const setUser = user => ({
  type: types.APPLICATION_SET_USER,
  payload: user,
});

export const setLang = lang => ({
  type: types.APP_SET_LANG,
  payload: lang,
});
