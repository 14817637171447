import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, toLower } from 'lodash';
import detectBrowserLanguage from 'detect-browser-language';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { setLang } from '../../services/Application/actions';
import Header from '../../components/Header';
import config from '../../config';
import translations from '../../intl/translations.json';

class AppWrapper extends Component {
  static propTypes = {
    Lang: PropTypes.string.isRequired,
    setLang: PropTypes.func.isRequired,
  }

  getLang = () => {
    const browserLang = detectBrowserLanguage().slice(0, 2);
    // Check if browers lang is avalible
    // other wise fall back to en.
    if (isEmpty(config.AVAILABLE_LANGUAGES.filter(l => l === toLower(browserLang)))) {
      this.props.setLang(config.AVAILABLE_LANGUAGES[0]);
      return config.AVAILABLE_LANGUAGES[0];
    }
    const { Lang } = this.props;
    if (!isEmpty(Lang)) {
      return toLower(Lang);
    }
    return toLower(browserLang);
  }

  render() {
    const lang = this.getLang();
    console.log('current locale:', lang);

    const {
      children,
    } = this.props;

    return (
      <IntlProvider
        locale={lang}
        messages={translations[lang] || {}}
      >
        <Fragment>
          <Header />
          {children}
          <ToastContainer />
        </Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  Lang: state.Application.lang,
});

const mapDispatchToProps = {
  setLang,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppWrapper));
