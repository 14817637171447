import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import firebase from 'firebase/app';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faFile,
  faUpload,
  faArrowDown,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';
import detectBrowserLanguage from 'detect-browser-language';
import { addLocaleData } from 'react-intl';
import Raven from 'raven-js';
import styled from 'styled-components';
import en from 'react-intl/locale-data/en';
import ja from 'react-intl/locale-data/ja';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setUser, setLang } from '../services/Application/actions';
import store from '../services/store';
import Router from '../routes';
import config from '../config';
import runningYagi from '../assets/running-yagi.png';
import './App.scss';

const Loading = styled.img`
  height: 15rem;
  width: auto;
  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(-360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(-360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform:
      rotate(-360deg);
      transform:rotate(-360deg);
    }
  }
`;

class App extends Component {
  constructor(props) {
    super(props);
    // Configure sentry
    Raven.config(config.SENTRY_DSN, {
      release: config.SENTRY_RELEASE,
    }).install();

    // Setup redux store
    this.store = store();

    // Add icons
    library.add(faSpinner);
    library.add(faFile);
    library.add(faUpload);
    library.add(faDownload);
    library.add(faArrowDown);

    ReactGA.initialize(config.GOOGLE_ANALYTICS, {
      // debug: config.STAGE !== 'production',
    });

    // eslint-disable-next-line no-undef
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Add localizations
    addLocaleData([
      ...en,
      ...ja,
    ]);
  }

  state = {
    initialized: false,
  }

  componentWillMount() {
    // Set default lanuage
    this.store.dispatch(setLang(detectBrowserLanguage().slice(0, 2)));

    // Configure firebase
    firebase.initializeApp(config.FIREBASE);

    // Login anonymously
    firebase.auth().signInAnonymously().catch((error) => {
      console.error(error);
      toast.error(error.message);
    });
  }

  componentDidMount() {
    // Get the loged in user
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.store.dispatch(setUser(user));
        // Google Analytics
        ReactGA.set({ userId: user.uid });
        // Raven
        Raven.setUserContext({
          id: user.uid,
        });
        this.setState({ initialized: true });
      } else {
        // TODO: Error message
      }
    });

    // Track app performance
    // eslint-disable-next-line no-undef
    if (window.performance) {
      // Gets the ms since page load.
      // eslint-disable-next-line no-undef
      const timeSince = Math.round(window.performance.now());

      ReactGA.timing({
        category: 'Application loaded',
        variable: 'load',
        value: timeSince,
      });
    }
  }

  render() {
    const { initialized } = this.state;

    return (
      <Provider store={this.store}>
        {initialized ? (
          <HashRouter>
            <Router />
          </HashRouter>
        ) : (
          <div className="d-flex flex-row justify-content-center align-items-center" style={{ height: '100%' }}>
            <Loading src={runningYagi} />
          </div>
        )}
      </Provider>
    );
  }
}

export default App;
