import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import withTracker from './utils/withTracker';

import Wrapper from './application/components/AppWrapper';

// Pages
import Upload from './pages/Upload';
import Download from './pages/Download';
import TermsAndConditions from './pages/TermsAndConditions';
import Privacy from './pages/Privacy';
import ContactUs from './pages/ContactUs';
import PageNotFound from './pages/PageNotFound';

class Routes extends Component {
  render() {
    return (
      <Wrapper>
        <Switch>
          <Route exact path="/" component={withTracker(Upload)} />
          <Route exact path="/d/:uid/:file" component={withTracker(Download)} />
          <Route exact path="/terms-and-conditions" component={withTracker(TermsAndConditions)} />
          <Route exact path="/privacy" component={withTracker(Privacy)} />
          <Route exact path="/contact-us" component={withTracker(ContactUs)} />
          <Route component={withTracker(PageNotFound)} />
        </Switch>
      </Wrapper>
    );
  }
}

export default Routes;
