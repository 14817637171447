import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Container,
  Row,
  Col,
  CardBody,
} from 'reactstrap';
import styled from 'styled-components';
import Footer from '../../components/Footer';

const Card = styled.div`
  border-radius: 0px !important;
  border-color: #adb5bd !important;
`;

class ContactUs extends Component {
  render() {
    return (
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card className="card">
              <CardBody>
                <h2 className="text-center">
                  <FormattedMessage id="ContactUs.Title" />
                </h2>
                <p className="text-center">
                  <FormattedMessage id="ContactUs.Description" />
                </p>
                <hr />
                <h5 className="text-center">
                  contact@yagisantask.com
                </h5>
              </CardBody>
            </Card>
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ContactUs;
