import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

const LinksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 5px;
  margin-bottom: 2rem;
`;

const Text = styled.span`
  color: rgba(92, 155, 22, 0.5);
`;

class Footer extends Component {
  render() {
    return (
      <LinksContainer>
        <Link to="contact-us">
          <Text>
            <FormattedMessage id="Footer.ContactUs" />
          </Text>
        </Link>
      </LinksContainer>
    );
  }
}

export default Footer;
