export default {
  STAGE: process.env.NODE_ENV || 'development',
  SENTRY_RELEASE: process.env.REACT_APP_RELEASE || '',
  GOOGLE_ANALYTICS: 'UA-136503396-1',
  // NOTE: First one in the list will be the system defalt.
  AVAILABLE_LANGUAGES: ['ja', 'en'],
  DEFAULT_ERROR: 'Sorry something went wrong',
  SENTRY_DSN: 'https://f4c39a1ab1574b94a4039ca71ea62f3e@sentry.io/1420338',
  FIREBASE: {
    apiKey: 'AIzaSyCdcZ7OWvGveM26Uzxe1ns4JdCbCyWq3XM',
    authDomain: 'yagisanfile.firebaseapp.com',
    databaseURL: 'https://yagisanfile.firebaseio.com',
    projectId: 'yagisanfile',
    storageBucket: 'yagisanfile.appspot.com',
    messagingSenderId: '756936070579',
  },
  DOWNLOAD_WAIT: 3,
};
