import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { isEmpty, toNumber, toUpper } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Clipboard from 'react-clipboard.js';
import { toast } from 'react-toastify';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import {
  Container,
  Row,
  Col,
  CardBody,
} from 'reactstrap';
import styled from 'styled-components';
import { connect } from 'react-redux';
import Uploader from './containers/Uploader';
import Progress from './containers/Progress';
import Footer from '../../components/Footer';
import introductionEN from '../../assets/introduction_en.png';
import introductionJA from '../../assets/introduction_ja.png';

const Card = styled.div`
  border-radius: 0px !important;
  border-color: #adb5bd !important;
`;

const CardTitle = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2rem;
`;

const BtnGroupContainer = styled.div`
  width: 100%;
`;

const RadioButton = styled.label`
  border-radius: 0px !important;
  box-shadow: 2px 2px #577c29;
  cursor: pointer;
`;

const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`;

const TopImage = styled.img``;

class Dashboard extends Component {
  static propTypes = {
    User: PropTypes.shape({
      uid: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      expiration: '604800',
      downloadURL: '',
      progress: 0,
    };
  }

  setDownloadURL = (url) => {
    this.setState({ downloadURL: url });
  }

  setProgress = (progress) => {
    this.setState({ progress });
  }

  onExpiryChange = (e) => {
    e.preventDefault();
    this.setState({
      expiration: e.target.value,
    });
  }

  getId = () => {
    const { User: { uid } } = this.props;
    if (isEmpty(uid)) {
      return uuid().toString();
    }
    return uid;
  }

  getHeaderImage = (lang) => {
    switch (toUpper(lang)) {
      case 'JA': return (
        <TopImage
          src={introductionJA}
          className="img-fluid"
        />
      );
      default: return (
        <TopImage
          src={introductionEN}
          className="img-fluid"
        />
      );
    }
  }

  onCopied = () => {
    toast.success(<FormattedMessage id="Upload.CopySuccess" />);
  }

  render() {
    const { expiration, progress } = this.state;
    const { intl } = this.props;

    return (
      <Container>
        <Row>
          <Col
            sm={{ size: 12, offset: 0 }}
            md={{ size: 10, offset: 1 }}
            lg={{ size: 8, offset: 2 }}
          >
            <Card className="card">
              <CardBody>
                {this.getHeaderImage(intl.locale)}
                <hr />
                {progress <= 0 && (
                  <form>
                    <div className="form-group">
                      <label className="form-label">
                        <FormattedMessage id="Upload.FileRetentionPeriod" />
                      </label>
                      <BtnGroupContainer className="btn-group btn-group-toggle">
                        <RadioButton
                          className={classNames('btn btn-primary', {
                            active: expiration === '604800',
                          })}
                        >
                          <input
                            type="radio"
                            name="optradio"
                            value="604800"
                            onChange={this.onExpiryChange}
                            checked={expiration === '604800'}
                          />
                          <FormattedMessage id="Upload.7Days" />
                        </RadioButton>
                        <RadioButton
                          className={classNames('btn btn-primary', {
                            active: expiration === '1209600',
                          })}
                        >
                          <input
                            type="radio"
                            name="optradio"
                            value="1209600"
                            onChange={this.onExpiryChange}
                            checked={expiration === '1209600'}
                          />
                          <FormattedMessage id="Upload.14Days" />
                        </RadioButton>
                        <RadioButton
                          className={classNames('btn btn-primary', {
                            active: expiration === '2592000',
                          })}
                        >
                          <input
                            type="radio"
                            name="optradio"
                            value="2592000"
                            onChange={this.onExpiryChange}
                            checked={expiration === '2592000'}
                          />
                          <FormattedMessage id="Upload.30Days" />
                        </RadioButton>
                      </BtnGroupContainer>
                    </div>
                  </form>
                )}
                <Uploader
                  setURL={this.setDownloadURL}
                  setProgress={this.setProgress}
                  uid={this.getId()}
                  hidden={progress > 0}
                  expiration={toNumber(expiration)}
                />
                {progress > 0 && (
                  <Fragment>
                    {progress === 100 ? (
                      <Fragment>
                        <CardTitle>
                          <FormattedMessage id="Upload.FinishedUploading" />
                        </CardTitle>
                        <CenterBlock>
                          <FontAwesomeIcon icon="arrow-down" size="5x" className="center-text" />
                        </CenterBlock>
                        <form onSubmit={e => e.preventDefault()}>
                          <div className="input-group mb-3">
                            <input
                              type="text"
                              className="form-control"
                              value={`https://www.yagisanfile.com/#/d/${this.state.downloadURL}`}
                              readOnly
                            />
                            <div className="input-group-append">
                              <Clipboard
                                component="button"
                                data-clipboard-text={`https://www.yagisanfile.com/#/d/${this.state.downloadURL}`}
                                className="btn btn-outline-primary"
                                onSuccess={this.onCopied}
                              >
                                <FormattedMessage id="Upload.Copy" />
                              </Clipboard>
                            </div>
                          </div>
                        </form>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <CardTitle>
                          <FormattedMessage id="Upload.Uploading" />
                        </CardTitle>
                        <Progress progress={progress} />
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </CardBody>
            </Card>
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  User: state.Application.user,
});

export default injectIntl(connect(mapStateToProps)(Dashboard));
