import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Circle } from 'rc-progress';
import { toString } from 'lodash';
import styled from 'styled-components';

const Container = styled.div`
  width: 175px;
  height: 175px;
  margin: auto;
  position: relative;
`;

const Title = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
`;

class Progress extends PureComponent {
  static propTypes = {
    progress: PropTypes.number.isRequired,
  }

  render() {
    const { progress } = this.props;

    return (
      <Container>
        <Title>
          {`${toString(Math.round(progress))}%`}
        </Title>
        <Circle
          percent={progress}
          strokeWidth="4"
          strokeColor="#6B9933"
        />
      </Container>
    );
  }
}

export default Progress;
