import React, { Component, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { storage, firestore } from 'firebase';
import { FormattedMessage } from 'react-intl';
import Raven from 'raven-js';
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import moment from 'moment';
import { toast } from 'react-toastify';
import styled from 'styled-components';

const getBorderColor = (props) => {
  if (props.isDragActive) {
    return '#6dba6d';
  }
  return '#6B9933';
};

const DropButton = styled.button`
  border-color: ${props => getBorderColor(props)} !important;
  border-style: ${props => props.isDragActive ? 'dashed' : 'none'} !important;
  border-width: ${props => props.isDragActive ? '3px' : '0px'} !important;
  border-radius: 0px !important;
  box-shadow: 2px 2px #577c29;
  padding-top: ${props => props.isDragActive ? '12px' : '15px'} !important;
  padding-bottom: ${props => props.isDragActive ? '7px' : '10px'} !important;
  transition: border .24s ease-in-out;
`;

function MyDropzone(props) {
  const onDrop = useCallback((acceptedFiles) => {
    // Get first file
    const file = acceptedFiles[0];
    if (file) {
      // Prepare for upload
      const fileExt = file.name.split('.').pop();
      const url = `${props.uid}/${shortid.generate()}.${fileExt}`;
      const fileRef = storage().ref().child(url);
      // Save upload request
      firestore().collection('files').add({
        uid: props.uid,
        url,
        fileName: file.name,
        size: file.size,
        timeout: props.expiration,
        deleteAt: moment().add(props.expiration, 'seconds').format(),
        createdAt: moment().format(),
      }).then(() => {
        // Upload the file and metadata
        const uploadTask = fileRef.put(acceptedFiles[0], {
          contentType: file.type,
        });

        // Register three observers:
        // 1. 'state_changed' observer, called any time the state changes
        // 2. Error observer, called on failure
        // 3. Completion observer, called on successful completion
        uploadTask.on('state_changed', (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and
          // the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          props.setProgress(progress);
          switch (snapshot.state) {
            case storage.TaskState.SUCCESS:
              console.log('Upload is SUCCESS');
              break;
            case storage.TaskState.PAUSED:
              console.log('Upload is paused');
              break;
            case storage.TaskState.RUNNING:
              console.log('Upload is running');
              break;
            default: break;
          }
        }, (error) => {
          // Handle unsuccessful uploads
          console.error(error);
          toast.error(error);
        }, () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          props.setURL(url);
        });
      }).then(() => {
        ReactGA.event({
          category: 'User',
          action: 'Uploaded file',
        });
      }).catch((error) => {
        Raven.captureException(error);
        // Handle unsuccessful uploads
        console.error(error);
        toast.error(error);
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <section
      {...getRootProps()}
      style={props.hidden ? { display: 'none' } : { display: 'block' }}
    >
      <input {...getInputProps()} />
      <div className="d-flex">
        <DropButton
          type="button"
          className={classNames('btn btn-primary btn-block', {
            active: isDragActive,
          })}
          isDragActive={isDragActive}
        >
          <FontAwesomeIcon icon="upload" size="3x" />
          <br />
          <br />
          <FormattedMessage id="Upload.SelectFile" />
        </DropButton>
      </div>
    </section>
  );
}

class Uploader extends Component {
  static propTypes = {
    uid: PropTypes.string.isRequired,
    setURL: PropTypes.func.isRequired,
    setProgress: PropTypes.func.isRequired,
    hidden: PropTypes.bool.isRequired,
    expiration: PropTypes.number.isRequired,
  }

  render() {
    const {
      uid,
      setURL,
      setProgress,
      hidden,
      expiration,
    } = this.props;

    return (
      <MyDropzone
        uid={uid}
        setURL={setURL}
        setProgress={setProgress}
        hidden={hidden}
        expiration={expiration}
      />
    );
  }
}

export default Uploader;
