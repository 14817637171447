import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setLang } from '../services/Application/actions';
import fbLogo from '../assets/facebook-logo.png';
import logoJP from '../assets/logo-jp.png';

const Container = styled.div`
  width: 100%;
  hright: 40px;
  background-color: #6dba6d;
  border-bottom-color: #ecf0f5;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-bottom: 2rem;
`;

const HideOnMobile = styled.div`
  @media (max-width: 576px) {
    display: none !important;
  }
  @media (min-width: 577px) {
    display: inline-block !important;
  }
`;

const YagisanLogo = styled.img`
  height: 100%;
  width: auto;
`;

const LangSelect = styled.select`
  margin-right: 10px;
  height: 25px;
`;

const NavIcon = styled.img`
  height: 25px;
  width: auto;
  margin-top: 0px;
  margin-bottom: 1px;
  padding-bottom: 0px;
  padding-top: 0px;
`;

class Header extends Component {
  static propTypes = {
    lang: PropTypes.string.isRequired,
    setLang: PropTypes.func.isRequired,
  }

  onLangSelect = (e) => {
    e.preventDefault();
    this.props.setLang(e.target.value);
  }

  render() {
    const { lang } = this.props;

    return (
      <Container>
        <div className="container">
          <Row>
            <Col
              sm={{ size: 12, offset: 0 }}
              md={{ size: 10, offset: 1 }}
              lg={{ size: 8, offset: 2 }}
            >
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <a href="/">
                    <YagisanLogo src={logoJP} />
                  </a>
                </div>
                <div>
                  <LangSelect
                    value={lang}
                    onChange={this.onLangSelect}
                  >
                    <option value="ja">日本語</option>
                    <option value="en">English</option>
                  </LangSelect>
                  <HideOnMobile>
                    <a
                      href="https://www.facebook.com/Yagisan-3147201278639079"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <NavIcon src={fbLogo} alt="facebook page link" />
                    </a>
                  </HideOnMobile>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  lang: state.Application.lang,
});

const mapDispatchToProps = {
  setLang,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
