import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { storage } from 'firebase';
import { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Raven from 'raven-js';
import {
  Container,
  Row,
  Col,
  CardBody,
} from 'reactstrap';
import styled from 'styled-components';
import config from '../../config';
import Footer from '../../components/Footer';

const Card = styled.div`
  border-radius: 0px !important;
  border-color: #adb5bd !important;
`;

const Button = styled.a`
  border-radius: 0px !important;
  box-shadow: 2px 2px #577c29;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
`;

class Download extends Component {
  static contextTypes = {
    router: PropTypes.shape({
      route: PropTypes.shape({
        match: PropTypes.shape({
          params: PropTypes.shape({
            uid: PropTypes.string.isRequired,
            file: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      timer: config.DOWNLOAD_WAIT,
      url: '',
      error: '',
    };

    this.isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
    this.isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
  }

  componentDidMount() {
    const timeKeeper = setInterval(() => {
      const { timer } = this.state;
      if (timer > 0) {
        this.setState({ timer: timer - 1 });
      } else {
        clearInterval(timeKeeper);
        console.log('start download');
        this.downloadFile();
      }
    }, 1000);
  }

  downloadFile = () => {
    const {
      router: {
        route: {
          match: {
            params: {
              uid,
              file,
            },
          },
        },
      },
    } = this.context;
    const url = `gs://yagisanfile.appspot.com/${uid}/${file}`;
    const fileRef = storage().refFromURL(url);
    fileRef.getDownloadURL().then((signedURL) => {
      this.setState({
        url: signedURL,
        error: '',
      });

      // Not usefull?
      // const xhr = new XMLHttpRequest();
      // xhr.responseType = 'blob';
      // xhr.onload = (event) => {
      //   var blob = xhr.response;
      // };
      // xhr.open('GET', signedURL);
      // xhr.send();

      // iOS devices do not support downloading. We have to inform user about this.
      if (/(iP)/g.test(navigator.userAgent)) {
        this.setState({
          error: 'Your device does not support files downloading. Please try again in desktop browser.',
        });
        window.open(signedURL, '_blank');
        return false;
      }

      // If in Chrome or Safari - download via virtual link click
      if (this.isChrome || this.isSafari) {
        // Creating new link node.
        const link = document.createElement('a');
        link.href = signedURL;
        link.setAttribute('target', '_blank');

        if (link.download !== undefined) {
          // Set HTML5 download attribute. This will prevent file from opening if supported.
          const fileName = signedURL.substring(signedURL.lastIndexOf('/') + 1, signedURL.length);
          link.download = fileName;
        }

        // Dispatching click event.
        if (document.createEvent) {
          const e = document.createEvent('MouseEvents');
          e.initEvent('click', true, true);
          link.dispatchEvent(e);
          return true;
        }
      }

      // Trigger download
      window.open(signedURL, '_blank');

      return true;
    }).catch((error) => {
      Raven.captureException(error);
      console.error(error);
    });
  }

  render() {
    const { timer, url } = this.state;

    return (
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <Card className="card">
              <CardBody>
                {isEmpty(url) ? (
                  <Fragment>
                    <h4 className="text-center">
                      <FormattedMessage
                        id="Download.WillStartIn"
                        values={{
                          time: timer,
                        }}
                      />
                    </h4>
                    <h1 className="text-center">
                      {timer}
                    </h1>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p>
                      <FormattedMessage id="Download.NotAuto" />
                    </p>
                    <Button
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary btn-block"
                    >
                      <FontAwesomeIcon icon="download" size="3x" />
                      <br />
                      <br />
                      <FormattedMessage id="Download.Download" />
                    </Button>
                  </Fragment>
                )}
              </CardBody>
            </Card>
            <Footer />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Download;
